module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Mail Masker","short_name":"Mail Masker","start_url":"/","background_color":"#FFFFFF","theme_color":"#82899A","display":"minimal-ui","icon":"src/images/favicon512.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"399c38218341e89940d268669ec396e7"},
    },{
      plugin: require('../node_modules/gatsby-plugin-ackee-tracker/gatsby-browser.js'),
      options: {"plugins":[],"domainId":"b79f0d27-bb42-4711-a8f2-58a666975d7e","server":"https://mail-masker-analytics.netlify.app","ignoreLocalhost":true,"detailed":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
